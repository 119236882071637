import React, { useState, useRef, useEffect } from 'react';
import { Link } from "gatsby";
import styled, {css} from 'styled-components';
import { BiCookie } from "react-icons/bi";

import CTALink from "./CTALink";
import logo from '../images/Easons-Logo.jpeg';

function MainHeader({ siteTitle, scrolled, navMenuItems = [] }) {
  const inputRef = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false);
  const [acceptCookies, setScceptCookies] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const cookiesSet = localStorage.getItem('cookies');
      setScceptCookies(cookiesSet);
    }
  }, []); 

  const onChange = (e) => {
    setMenuOpen(e.target.checked);
  }

  const onCookieClick = (e) => {
    setScceptCookies(true);
    if (typeof window !== 'undefined') {
      localStorage.setItem('cookies', true);
    }
  }

  return (
    <HeaderWrapper>
      {!acceptCookies && (
        <Notice>
          <h4><BiCookie />We use cookies</h4>
          <NoticeContent>
            <p>By using our website, you agree to the use of cookies as described in our <Link to='/'>cookie policy</Link></p>
            <button type="button" onClick={() => onCookieClick()}>Accept</button>
          </NoticeContent>
        </Notice>
      )}
      <Header type={'full'}>
        <HeaderInner>
          <LogoLink to="/">
            <Logo src={logo} alt={siteTitle} />
          </LogoLink>
          <Burger>
            <div className="menu cross menu--1">
              <label>
                <input type="checkbox" onChange={onChange} ref={inputRef} />
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="50" cy="50" r="30" />
                  <path className="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
                  <path className="line--2" d="M0 50h70" />
                  <path className="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
                </svg>
              </label>
            </div>
          </Burger>
          <Navigation open={menuOpen} onClick={() => {
            setMenuOpen(false);
            inputRef.current.checked = false
          }}>
            {navMenuItems && (
              <Menu>
              {navMenuItems.map((item, idx) => (
                <MenuItem key={`MenuItem-${idx}`}>
                  <CTALink {...item} isHeader />
                  {item.subLinks && item.subLinks.length > 0 && (
                    <Menu>
                      {item.subLinks.map((subLink, i) => (
                        <MenuItem key={`subLink-${subLink.route.page.title}-${idx}`}>
                          <Link to={`/${subLink.route.slug.current}`}>
                            <dl>
                              <dt>{subLink.route.page.title}</dt>
                            </dl>
                          </Link>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </MenuItem>
              ))}
            </Menu>
            )}
          </Navigation>
        </HeaderInner>
      </Header>
    </HeaderWrapper>
  );
}

export default MainHeader;


const Notice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #293032;
  padding: 8px 0 12px 0;

  @media (min-width: 600px) {
    height: 100px;
    padding: 4px 0 8px 0;
  }

  h4 {
    width: 90%;
    max-width: 760px;
    color: white;
    margin: 0 0 4px 0;
    font-size: 17px;

    @media (min-width: 600px) {
      font-size: 20px;
    }

    svg {
      color: white;
      font-size: 24px;
      margin: 0 5px -3px -4px;

      @media (min-width: 600px) {
        font-size: 28px;
        margin: 0 5px -7px -8px;
      }
    }
  }
`;

const NoticeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 760px;

  @media (min-width: 600px) {
    justify-content: flex-start;
  }

  p {
    color: white;
    font-size: 12px;
    font-weight: 500;
    margin: 0;

    @media (min-width: 600px) {
      font-size: 16px;
      margin: 3px 0 0 0;
    }

    a {
      @media (min-width: 600px) {
        margin-left: 6px;
      }

      &:active, &:focus {
        color: #8dbe23;
      }
    }
  }

  button {
    display: flex;
    background: white;
    border-radius: 5px;
    border: none;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 16px;
    margin: 0 0 2px 8px;
    cursor: pointer;
    transition: all 0.2s;

    @media (min-width: 600px) {
      font-size: 16px;
      margin: 0 0 2px 26px;
    }

    &:active {
      transform: scale(.95);
    }
  }
`;

const HeaderWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 0;
  z-index: 999999;
`;

const Header = styled.header`
  display: flex;
  width: ${props => props.type === 'slim' ? '90%' : '100%'};
  border-radius: ${props => props.type === 'slim' ? '10px' : '0'};
  background: #ffffff;
  max-width: ${props => props.type === 'slim' ? '1440px' : '100%'};
  box-shadow: 0px 10px 32px -10px rgba(0,0,0,0.1);
`;

const HeaderInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0 16px;
  transition: all 0.4s ease-in-out;

  @media (min-width: 1024px) {
    height: 90px;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  height: 100%;
  align-items: center;
`;

const Logo = styled.img`
  display: flex;
  width: 170px;
  z-index: 1;
  transition: all 0.4s ease-in-out;

  @media (min-width: 1240px) {
    width: 210px;
  }
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  background: white;
  position: absolute;
  top: 50px;
  background: white;
  box-shadow: 0px 10px 32px -10px rgb(0 0 0 / 10%);
  width: 100%;
  padding-top: 20px;
  left: 0;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;

  ${({open}) => open && css`max-height: 100vh`};

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    box-shadow: none;
    max-height: initial;
    overflow: visible;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 20px 20px 40px;
  align-items: center;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0;
  }
`;

const MenuItemFooter = styled.div`
  display: flex;
  width: 100%;
  background: #f0f4f5;
  padding: 16px;
  margin: 4px;
  border-radius: 5px;

  @media (min-width: 1240px) {
    padding: 24px;
    margin: 8px;
  }
`;

const MenuItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 1024px) {
    height: 100%;
    width: auto;
  }

  button {
    width: 100%;

    @media (min-width: 1024px) {
      margin-top: 0;
      width: auto;
    }
  }

  ${Menu} {
    display: none;

    @media (min-width: 1024px) {
      position: absolute;
      min-width: 227px;
      max-width: 600px;
      box-shadow: 0px 12px 13px rgb(65 62 101 / 20%);
      border-radius: 8px;
      border: 1px solid #E5E5E5;
      background-color: #FFFFFF;
      padding: 2px 0 8px 0;
      top: 87px;
      z-index: -99;
      opacity: 0;
      transition: opacity .2s,top .4s;
      pointer-events: none;
      left: -50%;
      right: auto;
      border-radius: 0 0 10px 10px;
      border: 1px solid #eae9f2;
      background-color: #ffffff;
      display: block;
      border-top: none;
      width: 390px;
      height: auto;
      z-index: 2;
    }

    @media (min-width: 1240px) {
      width: 450px;
    }

    li {
      a {
        width: 100%;
        border-radius: 5px;
        margin: 4px 4px 0;

        @media (min-width: 1240px) {
          margin: 8px 8px 0;
        }

        &:hover {
          color: #424242;
          background: #f0f4f5;
          width: 100%;
        }

        dl {
          padding: 8px 16px;
          margin: 0;

          @media (min-width: 1240px) {
            padding: 16px 24px;
          }

          dt {
            display: inline-block;
            font-size: 15px;
            font-weight: 900;
            
            @media (min-width: 1240px) {
              font-size: 16px;
            }
          }

          span {
            display: inline-block;
            white-space: nowrap;
            vertical-align: middle;
            padding: 1px 4px;
            text-transform: uppercase;
            font-size: 8px;
            border-radius: 3px;
            font-weight: bold;
            background: #4caf50;
            color: white;
            margin-top: -1px;
            margin-inline-start: 6px;

            @media (min-width: 1240px) {
              font-size: 9px;
              padding: 1px 5px;
              margin-top: -5px;
            }
          }

          dd {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            color: #83955c;
            max-width: 100%;

            @media (min-width: 1240px) {
              font-size: 15px;
            }
          }
        }
      }

      ${MenuItemFooter} {
        div {
          display: flex;
          flex-direction: column;
          flex: 1;
      
          p {
            display: inline-block;
            font-size: 15px;
            font-weight: 900;
            margin: 0;
            
            @media (min-width: 1240px) {
              font-size: 16px;
            }
          }
      
          span {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            color: #83955c;
            max-width: 80%;

            @media (min-width: 1240px) {
              font-size: 15px;
            }
          }
        }
      
        a {
          display: flex;
          width: auto;
          align-items: center;
          justify-content: flex-end;
          margin: 0;
        }
      }
    }
  }

  &:hover {
    ${Menu} {
      top: 87px;
      left: -14px;
      opacity: 1;
      pointer-events: visible;
    }
  }

  &.with-button {
    @media (min-width: 1024px) {
      margin-left: 16px;
    }
  }
`;

const Burger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  z-index: 1;

  @media (min-width: 1024px) {
    display: none;
  }

  input {
    display: none;
  }

  label {
    display: block;
    cursor: pointer;
    position: absolute;
    width: 70px;
    height: 70px;
    max-width: 70px;
    max-height: 70px;
  }

  .menu--1 label {
    bottom: 0;
    right: 0;
  }

  path {
    fill: none;
    stroke: #424242;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all .8s cubic-bezier(.645, .045, .355, 1);
  }

  circle {
    fill: #fff3;
    opacity: 0;
  }

  label:hover circle {
    opacity: 1;
  }

  .cross input:checked+svg {
    .line--1,
    .line--3 {
      --length: 22.627416998;
    }
    .line--2 {
      --length: 0;
    }
  }

  .menu--1 {
    background-color: #3f77e9;
    .line--1,
    .line--3 {
      --total-length: 126.64183044433594;
    }
    .line--2 {
      --total-length: 70;
    }
    input:checked+svg {
      .line--1,
      .line--3 {
        --offset: -94.1149185097;
      }
      .line--2 {
        --offset: -50;
      }
    }
  }
`;
