import React from 'react';
import styled from 'styled-components';

function Space() {
  return <SpaceElement />;
}

export default Space;

const SpaceElement = styled.div`
  display: flex;
  width: 100%;
  height: 16px;

  @media (min-width: 800px) {
    height: 24px;
  }

  @media (min-width: 1024px) {
    height: 32px;
  }
`;