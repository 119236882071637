import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { BiChevronDown } from "react-icons/bi";

import logo from "../images/Easons-Logo.jpeg";
import badge from "../images/EPS_328x212.jpeg";
import appleLogo from "../images/appstore.png";
import androidLogo from "../images/playstore.png";

function MainFooter() {
  const footerEndRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      footerEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuOpen]);

  const openMenu = (e) => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Footer>
      <FooterContent>
        <LogoDiv>
          <Logo src={logo} alt="logo" />
          <Badge src={badge} alt="badge" />
          <FooterPharmaLink>
            <a id='lnkPharmacyRegulation' href='http://pharmacyregulation.org/registers/pharmacy/registrationnumber/1037115'>
              <img id='imgLogo' alt='Online Pharmacy Logo' src='http://files.pharmacyregulation.org/OnlinePharmacyLogos/1037115.gif' style={{ height: '36px', width: '91px', borderWidth:'0px'}} />
            </a>
            <br/>
            <a id='lnkWhatIsThis' href='http://pharmacyregulation.org/registration/internet-pharmacy'>What is this?</a>
          </FooterPharmaLink>
        </LogoDiv>
        <MoreInfo onClick={() => openMenu()} open={menuOpen}>
          More information <BiChevronDown />
        </MoreInfo>
        <Row cols={"5"} style={{ marginBottom: "0" }} open={menuOpen}>
          <FooterNavigation>
            <h5>Overview</h5>
            <FooterMenu>
              <FooterMenuItem>
                <FooterMenuLink href="/">Home</FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink href="/about">About Us</FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink href="/services">Our Services</FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink href="/prescriptions">Prescriptions</FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink href="/health-advice">Health Advice</FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink href="/latest-news">Latest News</FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink href="/contact-us">Contact Us</FooterMenuLink>
              </FooterMenuItem>
            </FooterMenu>
          </FooterNavigation>

          <FooterNavigation>
            <h5>Useful Links</h5>
            <FooterMenu>
              <FooterMenuItem>
                <FooterMenuLink href="https://www.nhs.uk/" target="_blank" rel="noreferrer">
                  NHS Choices
                </FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink href="https://www.england.nhs.uk/" target="_blank" rel="noreferrer">
                  NHS England
                </FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink href="https://patient.info/" target="_blank" rel="noreferrer">
                  Patient UK
                </FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink
                  href="https://www.fitfortravel.nhs.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fit For Travel
                </FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink
                  href="https://www.pharmacyregulation.org/i-am-patient-or-member-public"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pharmacy Regulation
                </FooterMenuLink>
              </FooterMenuItem>
              <FooterMenuItem>
                <FooterMenuLink
                  href="https://www.nhs.uk/nhs-services/prescriptions-and-pharmacies/pharmacies/how-your-pharmacy-can-help/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ask Your Pharmacist
                </FooterMenuLink>
              </FooterMenuItem>
            </FooterMenu>
          </FooterNavigation>

          <FooterNavigation>
            <h5>Registrations</h5>
            <FooterMenu>
              <FooterMenuItem>
                <h6>Registered Pharmacy:</h6>
                <p>Kilcrona Ltd</p>
                <p>215a Watling Street, Wilnecote, Tamworth, Staffordshire, B77 5BB</p>
                <p>Telephone: <a href="tel:01827280837">01827 280837</a></p>
                <p>
                  GPhC Registration Number:{" "}
                  <a
                    href="https://www.pharmacyregulation.org/registers/pharmacy/registrationnumber/1037115"
                    target="_blank"
                    rel="noreferrer"
                  >
                    1037115
                  </a>
                </p>
              </FooterMenuItem>
              <FooterMenuItem>
                <h6>Registered Pharmacist:</h6>
                <p>Kieran Eason</p>
                <p>
                  GPhC Registration Number:{" "}
                  <a
                    href="https://www.pharmacyregulation.org/registers/pharmacist/registrationnumber/2065453"
                    target="_blank"
                    rel="noreferrer"
                  >
                    2065453
                  </a>
                </p>
              </FooterMenuItem>
            </FooterMenu>
          </FooterNavigation>
        </Row>
        <div ref={footerEndRef} />
      </FooterContent>
    </Footer>
  );
}

export default MainFooter;

const LogoDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    padding: 0 24px 0 0;
  }
`;

const Logo = styled.img`
  display: flex;
  width: 40%;
  max-width: 130px;

  @media (min-width: 1024px) {
    width: 100%;
    max-width: 170px;
  }
`;

const Badge = styled.img`
  display: flex;
  width: 20%;
  max-width: 70px;

  @media (min-width: 1024px) {
    width: 90%;
    margin-top: 24px;
    max-width: 90px;
  }
`;

const MoreInfo = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #f0f4f5;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0;
  margin: 16px 0 0;
  border-radius: 7px;

  svg {
    font-size: 24px;
  }

  @media (min-width: 600px) {
    display: none;
  }

  ${({ open }) =>
    open &&
    css`
      svg {
        transform: rotate(-180deg);
      }
    `};
`;

const Row = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  margin-bottom: 32px;
  width: 100%;
  max-height: 0;
  overflow: hidden;

  ${({ open }) =>
    open &&
    css`
      margin-top: 16px;
      max-height: 10000px;
    `}

  @media (min-width: 600px) {
    margin-top: 24px;
    max-height: initial;
  }

  @media (min-width: 1024px) {
    margin-top: 0;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: white;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1420px;
  padding: 24px 0;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const FooterPharmaLink = styled.div`
  @media (min-width: 1024px) {
    margin-top: 16px
  }

  #lnkWhatIsThis {
    font-size: 12px;
  }
`;

const FooterNavigation = styled.nav`
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 18px;
    margin: 0 0 16px 0;
  }
`;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
`;

const FooterMenuItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 0 0;

  h6,
  p {
    font-size: 14px;
    margin: 0;
  }
`;

const FooterMenuLink = styled.a`
  display: flex;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #424242;
  padding: 0;
  transition: all 0.8s;

  &:after {
    transition: all 0.8s;
  }

  &:active,
  &:focus {
    color: #424242;
  }

  &:hover {
    color: #8dbe23;
  }
`;
